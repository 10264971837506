@font-face {
  font-family: 'Avenir LT Std';
  src: url('../assets/fonts/AvenirLTStd-Roman.eot');
  src: url('../assets/fonts/AvenirLTStd-Roman.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/AvenirLTStd-Roman.woff2') format('woff2'),
    url('../assets/fonts/AvenirLTStd-Roman.woff') format('woff'),
    url('../assets/fonts/AvenirLTStd-Roman.ttf') format('truetype'),
    url('../assets/fonts/AvenirLTStd-Roman.svg#AvenirLTStd-Roman') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir LT Std';
  src: url('../assets/fonts/AvenirLTStd-Medium.eot');
  src: url('../assets/fonts/AvenirLTStd-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/AvenirLTStd-Medium.woff2') format('woff2'),
    url('../assets/fonts/AvenirLTStd-Medium.woff') format('woff'),
    url('../assets/fonts/AvenirLTStd-Medium.ttf') format('truetype'),
    url('../assets/fonts/AvenirLTStd-Medium.svg#AvenirLTStd-Medium')
      format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir LT Std';
  src: url('../assets/fonts/AvenirLTStd-Book.eot');
  src: url('../assets/fonts/AvenirLTStd-Book.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/AvenirLTStd-Book.woff2') format('woff2'),
    url('../assets/fonts/AvenirLTStd-Book.woff') format('woff'),
    url('../assets/fonts/AvenirLTStd-Book.ttf') format('truetype'),
    url('../assets/fonts/AvenirLTStd-Book.svg#AvenirLTStd-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}
